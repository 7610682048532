type LinkData = {
  label: string;
  url: string;
};

export type EmailButton = {
  label: string;
  link: string;
};

export type NavItemData = {
  __typename:
    | 'ComponentUiNavLink'
    | 'ComponentUiServicesDropdown'
    | 'ComponentUiHubsDropdown';
  label: string;
  icon?: string;
  url?: string;
};

export type PopularCategoryData = {
  heading: string;
  description: string;
  PopularCategoryCards: {
    service_type: {
      id: string;
      name: string;
      is_community_service?: boolean;
    };
  }[];
};

type UploadFile = {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  alternativeText: string | null;
  caption: string | null;
  width: number | null;
  height: number | null;
  formats: unknown;
  hash: string;
  ext: string | null;
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: unknown;
};

// Our fixed set of responses
export enum YesNoSometimes {
  Yes = 'yes',
  No = 'no',
  Sometimes = 'sometimes',
}

export type TextBlock = {
  heading: string;
  heading_style: 'h2' | 'h3' | 'h4';
  body: string;
};

export type TextBlockSectionData = {
  __typename: 'ComponentUiTextBlock';
  id: string;
  heading: string;
  heading_style: 'h2' | 'h3' | 'h4';
  body: string;
};

export type FaqsSectionData = {
  __typename: 'ComponentUiFaqSection';
  id: string;
  FaqCards: {
    faq: {
      question: string;
      slug: string;
    };
  }[];
};

export type HubsListData = {
  title: string;
  slug: string;
  description: string;
  illustration: Pick<UploadFile, 'url'>;
}[];

export type TwoColumnTextSectionData = {
  __typename: 'ComponentUiTextSection';
  id: string;
  TextBlock: TextBlock[];
};

export type ImageTextSectionData = {
  __typename: 'ComponentUiImageTextSection';
  id: string;
  Image: Pick<UploadFile, 'url' | 'alternativeText' | 'width' | 'height'>;
  Text?: TextBlock;
};

export type ImageSectionData = {
  __typename: 'ComponentUiImage';
  id: string;
  image: Pick<UploadFile, 'url' | 'alternativeText' | 'width' | 'height'>;
};

export type VideoSectionData = {
  __typename: 'ComponentUiVideoSection';
  id: string;
  video_title: string;
  video_link: string;
};

// add other types to array as they are made available to the page dynamic zone
export type SectionOptionData =
  | FaqsSectionData
  | TwoColumnTextSectionData
  | ImageTextSectionData
  | TextBlockSectionData
  | ImageSectionData
  | VideoSectionData;
export type SectionsData = SectionOptionData[];

export type GlobalData = {
  global: {
    SiteNotice: {
      visible: boolean;
      text: string;
      Link: LinkData | null;
    };
    Footer: {
      logo: Pick<UploadFile, 'url'>;
      NominateButton: EmailButton;
      tagline: string;
      LinksLeftColumn: LinkData[];
      LinksRightColumn: LinkData[];
      acknowledgement: string;
      awardsText: string;
      awardsBadge: Pick<UploadFile, 'url'>;
      flags: Pick<UploadFile, 'url' | 'alternativeText'>[];
      copyright: string;
      funding: string;
      phn_logo: Pick<UploadFile, 'url'>;
      survey_embed_url: string;
      phn_website_url?: string;
    };
    PrimaryNavigationLinks: Array<NavItemData>;
    PrimaryNavigationOptions: {
      saved_label: string;
      logo: {
        url: string;
      };
      website_description?: string;
    };
    PopularCategories: PopularCategoryData;
    hubs_list_heading: string;
    related_services_heading: string;
    faq_list_heading: string;
    faq_list_button_label: string;
    BlogPostsSectionBlogPostPage?: {
      heading?: string;
      button_link?: string;
      button_label?: string;
    };
  };
  serviceTypes: {
    name: string;
    serviceCount: number;
  }[];
  hubNavItems: {
    title: string;
    slug: string;
  }[];
  homePage: {
    HomeHeader: {
      heading: string;
    };
  };
  feedbackForm: {
    URL: string | null;
  };
};

const metaDataFragment = `
  meta_title
  meta_description
  share_image {
    url
  }
`;

const globalFragment = `
global {
  SiteNotice {
    visible
    text
    Link {
      label
      url
    }
  }
  Footer {
    logo {
      url
    }
    NominateButton {
      label
      link
    }
    tagline
    LinksLeftColumn {
      label
      url
    }
    LinksRightColumn {
      label
      url
    }
    acknowledgement
    awardsText
    awardsBadge {
      url
    }
    flags {
      url
      alternativeText
    }
    copyright
    funding
    phn_logo {
      url
    }
    survey_embed_url
    phn_website_url
  }
  PrimaryNavigationOptions {
    website_description
    saved_label
    logo {
      url
    }
  }
  PrimaryNavigationLinks: primary_navigation_links {
    __typename
    ... on ComponentUiServicesDropdown {
      id
      label
    }
    ... on ComponentUiHubsDropdown {
      id
      label
    }
    ... on ComponentUiNavLink {
      id
      label
      url
    }
  }
  PopularCategories {
    heading
    description
    ... on ComponentUiPopularCategories {
      PopularCategoryCards {
        service_type {
          id
          name
          is_community_service
        }
      }
    }
  }
  hubs_list_heading
  related_services_heading
  faq_list_heading
  faq_list_button_label
  BlogPostsSectionBlogPostPage {
    heading
    button_link
    button_label
  }
}
serviceTypes: serviceTypesWithServiceCount {
  name
  serviceCount
}
hubNavItems: hubs(sort: "order:asc"){
  title
  slug
}
feedbackForm {
  URL
}
homePage {
  HomeHeader {
    description
  }
}
`;

export const GLOBAL_QUERY = `
query {
  ${globalFragment}
}
`;

export type Error404FragmentData = {
  hubsFor404: {
    title: string;
    slug: string;
  }[];
};

const error404Fragment = `
hubsFor404: hubs(sort: "order:asc") {
  title
  slug
}
`;

export type HomeData = {
  homePage: {
    meta_title?: string;
    meta_description?: string;
    share_image?: Pick<UploadFile, 'url'> | null;
    HomeHeader?: {
      is_campaign_banner: boolean;
      description?: string;
      heading?: string;
      button_1?: {
        label: string;
        url: string;
      };
      button_2?: {
        label: string;
        url: string;
      };
      illustration_large_header?: Pick<UploadFile, 'url'>;
      illustration_small_header?: Pick<UploadFile, 'url'>;
    };
    PromoLink: {
      title: string;
      description: string;
      Link: LinkData;
    } | null;
    FaqsSection: FaqsSectionData;
    BlogPostsSection: {
      heading?: string;
      button_link?: string;
      button_label?: string;
    };
  };
  hubs: HubsListData;
  blogPosts: BlogPostTileData[];
} & GlobalData;

export const HOME_QUERY = `
query {
  homePage {
    ${metaDataFragment}
    HomeHeader {
      is_campaign_banner
      heading
      description
      button_1 {
        label
        url
      }
      button_2 {
        label
        url
      }
      illustration_large_header {
        url
      }
      illustration_small_header {
        url
      }
    }
    PromoLink {
      title
      description
      Link {
        label
        url
      }
    }
    FaqsSection {
      __typename
      id
      FaqCards {
        faq {
          id
          question
          slug
        }
      }
    }
    BlogPostsSection {
      heading
      button_link
      button_label
    }
  }
  hubs(sort: "order:asc") {
    title
    slug
    description
    illustration {
      url
    }
  }
  blogPosts(limit: 3, sort: "published_at:desc") {
    id
    published_at
    slug
    title
    description
    hero_image {
      url
    }
    blog_categories {
      category
    }
  }
  ${globalFragment}
}
`;

export type SearchData = GlobalData;

export const SEARCH_QUERY = `
query {
  ${globalFragment}
}`;

export type StandardContentData = {
  pages: [
    page?: {
      title: string;
      subtitle: string;
      meta_title?: string;
      meta_description?: string;
      share_image?: Pick<UploadFile, 'url'> | null;
      sections: SectionsData;
    }
  ];
} & Error404FragmentData &
  GlobalData;

export const STANDARD_CONTENT_QUERY = (slug: string) => `
query {
  pages(limit: 1, where: { slug: "${slug}" }) {
    title
    subtitle
    ${metaDataFragment}
    sections {
      __typename
      ... on ComponentUiFaqSection {
        id
        FaqCards {
          faq {
            id
            question
            slug
          }
        }
      }
      ... on ComponentUiTextSection {
        id
        TextBlock {
          heading
          heading_style
          body
        }
      }
      ... on ComponentUiImageTextSection {
        id
        Image {
          url
          alternativeText
          width
          height
        }
        Text: TextBlock {
          heading
          heading_style
          body
        }
      }
    }
  }
  ${error404Fragment}
  ${globalFragment}
}
`;

export enum LocationType {
  Country = 'Country',
  State = 'State',
  LGA = 'LGA',
  Suburb = 'Suburb',
}

export type ServiceTypesData = {
  name: string;
  services: Pick<ServiceData, 'id'>[];
};

export type ServiceData = {
  id: string;
  slug?: string;
  title: string;
  provider: string;
  description: string;
  meta_title?: string;
  meta_description?: string;
  share_image?: Pick<UploadFile, 'url'> | null;
  hubs: HubsListData;
  areas_serviced: {
    name: string;
    type: LocationType;
  }[];
  when?: string;
  service_delivery_formats?: {
    name: string;
  }[];
  languages?: {
    name: string;
  }[];
  Venue: {
    name: string;
    address: string;
    accessibilities?: {
      name: string;
    }[];
    transport?: string;
    latitude?: string;
  } | null;
  website?: string;
  EmailAddresses: {
    email_address: string;
  }[];
  PhoneNumbers: {
    phone_number: string;
    contact_name?: string;
  }[];
  postal_address?: string;
  cost?: YesNoSometimes;
  price?: string;
  cost_comment?: string;
  service_types: ServiceTypesData[];
  booking_needed?: YesNoSometimes;
  referral_needed?: YesNoSometimes;
};

export type ServicePageData = {
  services: [service?: ServiceData];
} & Error404FragmentData &
  GlobalData;

export const SERVICE_QUERY = (slug: string) => `
query {
  services(limit: 1, where: { slug: "${slug}" }) {
    id
    title
    provider
    description
    ${metaDataFragment}
    hubs {
      title
      slug
      description
      illustration {
        url
      }
    }
    areas_serviced {
      name
      type
    }
    when
    service_delivery_formats {
      name
    }
    languages {
      name
    }
    Venue {
      name
      address
      accessibilities {
        name
      }
      transport
      latitude
    }
    website
    EmailAddresses {
      email_address
    }
    PhoneNumbers {
      phone_number
      contact_name
    }
    postal_address
    cost
    price
    cost_comment
    service_types {
      name
      services {
        id
      }
    }
    booking_needed
    referral_needed
  }
  ${error404Fragment}
  ${globalFragment}
}
`;

type ConversationCardsData = {
  conversation_starter: {
    id: string;
    title: string;
    description: string;
    slug: string;
    Questions: {
      id: string;
      question: string;
    }[];
  };
};

export type HubData = {
  conversationStarters: ConversationStarter[];
  hubs: [
    hub?: {
      title: string;
      description: string;
      illustration_desktop_header: Pick<UploadFile, 'url'>;
      illustration_mobile_header: Pick<UploadFile, 'url'>;
      meta_title?: string;
      meta_description?: string;
      share_image?: Pick<UploadFile, 'url'> | null;
      text_block: string;
      ConversationCards: ConversationCardsData[];
      categories: {
        service_type: {
          name: string;
          services: Pick<ServiceData, 'id'>[];
        };
      }[];
      services: Pick<
        ServiceData,
        'id' | 'title' | 'provider' | 'description' | 'slug'
      >[];
      FeaturedServices: {
        service: Pick<
          ServiceData,
          'id' | 'title' | 'provider' | 'description' | 'slug'
        >;
      }[];
      FaqsSection: FaqsSectionData;
    }
  ];
} & Error404FragmentData &
  GlobalData;

export const HUB_QUERY = (slug: string) => `
query {
  conversationStarters {
    id
    title
    description
    Questions {
      id
      question
    }
  }
  hubs(limit: 1, where: { slug: "${slug}" }) {
    title
    description
    illustration_desktop_header {
      url
    }
    illustration_mobile_header {
      url
    }
    ${metaDataFragment}
    text_block
    ConversationCards {
      conversation_starter {
        id
        title
        description
        Questions {
          id
          question
        }
      }
    }
    categories {
      service_type {
        name
        services {
          id
        }
      }
    }
    services (sort: "published_at:desc", limit: 4) {
      id
      title
      provider
      description
      slug
    }
    FeaturedServices {
      service {
        id
        title
        provider
        description
        slug
      }
    }
    FaqsSection {
      __typename
      id
      FaqCards {
        faq {
          id
          question
          slug
        }
      }
    }
  }
  ${error404Fragment}
  ${globalFragment}
}
`;

export type FaqDetailsData = {
  faqs: [
    faq?: {
      id: string;
      meta_title?: string;
      meta_description?: string;
      share_image?: Pick<UploadFile, 'url'> | null;
      question: string;
      description: string;
      conversation_starters: {
        title: string;
        slug: string;
      }[];
      Resources: {
        title: string;
        description: string;
        url: string;
      }[];
    }
  ];
  faqPage: {
    TopDisclaimer: {
      title: string;
      description: string;
    };
    BottomDisclaimer: {
      title: string;
      description: string;
    };
    illustration_mobile_header: Pick<UploadFile, 'url'>;
    illustration_desktop_header: Pick<UploadFile, 'url'>;
  };
  faqListPage: {
    description: string;
  };
} & Error404FragmentData &
  GlobalData;

export const FAQ_DETAILS_QUERY = (slug: string) => `
query {
  faqs(limit: 1, where: { slug: "${slug}" }) {
    id
    question
    description
    ${metaDataFragment}
    conversation_starters {
      title
      slug
    }
    Resources {
      title
      description
      url
    }
  }
  faqPage {
    TopDisclaimer {
      title
      description
    }
    BottomDisclaimer {
      title
      description
    }
    illustration_mobile_header {
      url
    }
    illustration_desktop_header {
      url
    }
  }
  faqListPage {
    description
  }
  ${error404Fragment}
  ${globalFragment}
}
`;

export type FaqListData = {
  faqListPage: {
    title: string;
    description: string;
    illustration_mobile_header: Pick<UploadFile, 'url'>;
    illustration_desktop_header: Pick<UploadFile, 'url'>;
    meta_title?: string;
    meta_description?: string;
    share_image?: Pick<UploadFile, 'url'> | null;
  };
  faqs: {
    question: string;
    slug: string;
  }[];
} & GlobalData;

export const FAQ_LIST_QUERY = `
query {
  faqListPage {
    title
    description
    illustration_mobile_header {
      url
    }
    illustration_desktop_header {
      url
    }
    ${metaDataFragment}
  }
  faqs(sort: "order:asc") {
    question
    slug
  }
  ${globalFragment}
}
`;

export type ConversationStarter = {
  id: string;
  title: string;
  description: string;
  slug: string;
  meta_title?: string;
  meta_description?: string;
  share_image?: Pick<UploadFile, 'url'> | null;
  Questions: {
    question: string;
  }[];
};

export type ConversationStarterData = {
  conversationStarters: ConversationStarter[];
} & Error404FragmentData &
  GlobalData;

export const CONVERSATION_STARTER_QUERY = `
query {
  conversationStarters {
    title
    description
    slug
    ${metaDataFragment}
    Questions {
      question
    }
  }
  ${error404Fragment}
  ${globalFragment}
}
`;

export type GlossaryData = {
  glossaryPage: {
    title: string;
    description: string;
    illustration_mobile_header: Pick<UploadFile, 'url'>;
    illustration_desktop_header: Pick<UploadFile, 'url'>;
    meta_title?: string;
    meta_description?: string;
    share_image?: Pick<UploadFile, 'url'> | null;
  };
  glossaryTerms: {
    word: string;
    definition: string;
  }[];
} & GlobalData;

export const GLOSSARY_QUERY = `
query {
  glossaryPage {
    title
    description
    illustration_mobile_header {
      url
    }
    illustration_desktop_header {
      url
    }
    ${metaDataFragment}
  }
  glossaryTerms {
    word
    definition
  }
  ${globalFragment}
}
`;

export type SavedListData = {
  faqs: {
    id: string;
    question: string;
    description: string;
    slug: string;
  }[];
  services: {
    id: string;
    title: string;
    description: string;
    slug: string;
    areas_serviced: {
      name: string;
    }[];
    EmailAddresses: {
      email_address: string;
    }[];
    PhoneNumbers: {
      phone_number: string;
      contact_name?: string;
    }[];
  }[];
};

export const SAVED_LIST_QUERY = (faqIds: string[], serviceIds: string[]) => {
  const faqs = faqIds.length === 0 ? '-1' : faqIds.join(',');
  const services = serviceIds.length === 0 ? '-1' : serviceIds.join(',');
  return `
    query {
      faqs(where: { id: [${faqs}] }) {
        id
        question
        description
        slug
      }
      services(where: { id: [${services}] }) {
        id
        title
        description
        slug
        areas_serviced {
          name
        }
        EmailAddresses {
          email_address
        }
        PhoneNumbers {
          phone_number
          contact_name
        }
      }
    }
  `;
};

export type SavedPageData = {
  savedPage: {
    title: string;
    subtitle: string;
    description: string;
    meta_title?: string;
    meta_description?: string;
    share_image?: Pick<UploadFile, 'url'> | null;
    explanation: string;
    Disclaimer: {
      title: string;
      description: string;
    };
    illustration_mobile_header: Pick<UploadFile, 'url'>;
    illustration_desktop_header: Pick<UploadFile, 'url'>;
  };
} & GlobalData;

export const SAVED_PAGE_QUERY = `
query {
  savedPage {
    title
    subtitle
    description
    ${metaDataFragment}
    explanation
    Disclaimer {
      title
      description
    }
    illustration_mobile_header {
      url
    }
    illustration_desktop_header {
      url
    }
  }
  ${globalFragment}
}
`;

export type Error404Data = Error404FragmentData & GlobalData;

export const ERROR_404_QUERY = `
query {
  ${error404Fragment}
  ${globalFragment}
}
`;

export type BlogPostData = {
  blogPosts: [
    blogPost?: {
      id: string;
      published_at: string;
      slug: string;
      title: string;
      description: string;
      meta_title?: string;
      meta_description?: string;
      share_image?: Pick<UploadFile, 'url'> | null;
      hero_image: Pick<
        UploadFile,
        'url' | 'alternativeText' | 'width' | 'height'
      >;
      sections: SectionsData;
      blog_categories?:
        | {
            category: string;
          }[]
        | undefined;
    }
  ];
  recentBlogPosts: BlogPostTileData[];
} & Error404FragmentData &
  GlobalData;

export const BLOG_POST_QUERY = (slug: string) => `
query {
  blogPosts(limit: 1, where: { slug: "${slug}" }) {
    id
    published_at
    slug
    title
    description
    ${metaDataFragment}
    hero_image {
      height
      width
      url
      alternativeText
    }
    sections {
      __typename
      ... on ComponentUiTextSection {
        id
        TextBlock {
          heading
          heading_style
          body
        }
      }
      ... on ComponentUiImageTextSection {
        id
        Image {
          url
          alternativeText
          width
          height
        }
        Text: TextBlock {
          heading
          heading_style
          body
        }
      }
      ... on ComponentUiImage {
        id
        image {
          height
          width
          url
          alternativeText
        }
      }
      ... on ComponentUiTextBlock {
        id
        heading
        heading_style
        body
      }
      ... on ComponentUiVideoSection {
        id
        video_link
        video_title
      }
      ... on ComponentUiFaqSection {
        id
        FaqCards {
          faq {
            id
            question
            slug
          }
        }
      }
    }
    blog_categories {
      category
    }
	}
  recentBlogPosts: blogPosts (limit: 3, sort: "published_at:desc") {
    id
    published_at
    slug
    title
    description
    hero_image {
      url
    }
    blog_categories {
      category
    }
  }
  ${globalFragment}
}
`;

export type BlogPostTileData = {
  id: string;
  published_at: string;
  slug: string;
  title: string;
  description: string;
  hero_image: Pick<UploadFile, 'url' | 'alternativeText' | 'width' | 'height'>;
  blog_categories?: {
    category: string;
  }[];
};

export type BlogListData = {
  blogListPage: {
    title?: string;
    description?: string;
    meta_title?: string;
    meta_description?: string;
    share_image?: Pick<UploadFile, 'url'> | null;
    illustration_mobile_header: {
      url: string;
    } | null;
    illustration_desktop_header: {
      url: string;
    } | null;
  };
  blogPosts: BlogPostTileData[];
  blogCategories?: {
    category: string;
    blog_posts: {
      id: string;
    }[];
  }[];
} & GlobalData;

export const BLOG_LIST_QUERY = `
query {
  blogListPage {
    title
    description
    illustration_mobile_header {
      url
    }
    illustration_desktop_header {
      url
    }
    ${metaDataFragment}
  }
  blogPosts(sort: "published_at:desc") {
    id
    published_at
    slug
    title
    description
    hero_image {
      url
    }
    blog_categories {
      category
    }
  }
  blogCategories {
    category
    blog_posts {
      id
    }
  }
  ${globalFragment}
}
`;

export type ResourceTileData = {
  id: string;
  published_at: string;
  title: string;
  description: string;
  hero_image: Pick<UploadFile, 'url' | 'alternativeText' | 'width' | 'height'>;
  document?: Pick<UploadFile, 'url' | 'updated_at'>;
  external_resource_url?: string;
};

export type ResourceListData = {
  resourceListPage: {
    title?: string;
    description?: string;
    meta_title?: string;
    meta_description?: string;
    share_image?: Pick<UploadFile, 'url'> | null;
    illustration_mobile_header: {
      url: string;
    } | null;
    illustration_desktop_header: {
      url: string;
    } | null;
  };
  resources: ResourceTileData[];
} & GlobalData;

export const RESOURCE_LIST_QUERY = `
query {
  resourceListPage {
    title
    description
    illustration_mobile_header {
      url
    }
    illustration_desktop_header {
      url
    }
    ${metaDataFragment}
  }
  resources(sort: "published_at:desc") {
    id
    published_at
    title
    description
    hero_image {
      url
    }
    external_resource_url
    document {
      url,
      updated_at
    }
  }
  ${globalFragment}
}
`;
